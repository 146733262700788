<script lang="ts" setup>
import type { HeroBanner } from '@integration-layer/ampliance/schemas/hero-banner-schema.localized'
import type { EditorialContentProps } from '@design-system/components/Editorial/EditorialContent.props'
import type { UIMediaProps } from '@design-system/components/UI/UIMedia.props'

const props = defineProps<{
  deliveryId: string
  locale: string
  vse?: string
  content?: HeroBanner
}>()

const data = await useComponentResponse<HeroBanner>(props)

const lgImages: ComputedRef<UIMediaProps[]> = computed(
  () =>
    data.value?.bgDesktopMedia?.map(image => ({
      ...image,
      media: {
        // @ts-ignore
        ...image?.media,
        sizes: '100vw lg:80vw',
      },
    })) ?? []
)
const smImages: ComputedRef<UIMediaProps[]> = computed(
  () =>
    data.value?.bgMobileMedia?.map(image => ({
      ...image,
      media: {
        // @ts-ignore
        ...image?.media,
        sizes: '100vw lg:100vw',
      },
    })) ?? []
)
</script>

<template>
  <EditorialContent
    v-if="data"
    v-bind="data as unknown as EditorialContentProps"
    :bg-mobile-media="smImages"
    :bg-desktop-media="lgImages"
  />
</template>
